import React, {useEffect} from 'react';
import Modal from 'react-modal';
import Login from "./login";
import {useModal} from "../../utils/ModalContext";

function Auth(props) {
  const { isModalOpen, closeModal } = useModal();

  if (!isModalOpen) return null;


  const handleModalClick = (event) => {
    event.stopPropagation();
  };

  return (
    <div className="fixed inset-0 z-10 w-screen overflow-y-auto" onClick={()=>{closeModal()}}>
      <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div onClick={handleModalClick}>
        <Login  />
      </div>
      </div>
    </div>
  );
}

export default Auth;

import React from 'react';

function Login() {
  return (
    <div>
      <section>
        <div>
          <form className="bg-blue-500 w-full text-center px-3 py-4 mx-auto rounded">
            <input type="text" placeholder="Username" className="block w-full mx-auto text-sm py-2 px-3 rounded"/>
            <input type="text" placeholder="Password" className="block w-full mx-auto text-sm py-2 px-3 rounded my-3"/>
            <button className="bg-blue text-white font-bold py-2 px-4 rounded border block mx-auto w-full">
              Login
            </button>
          </form>
        </div>
      </section>
    </div>
  );
}

export default Login;
